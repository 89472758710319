<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="processes-list-filter" v-on:submit.prevent="applyFilter()">
              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" />
                </div>
              </div>
              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <!-- Data inicial -->
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.begin_date')"
                               id="begin_date" ref="begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoDtInicio)">
                        <label :class="{'active': filters.filPeriodoDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Data Final -->
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.end_date')"
                               id="end_date" ref="end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filPeriodoDtFim)">
                        <label :class="{'active': filters.filPeriodoDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Operation / Modal -->
              <h6 class="mb-3 mt-5">{{ $t('operation type and modal') }}</h6>
              <div class="row">
                <!-- Filter Operation  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Importação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.impo}">
                        <input id="operation_impo" value="2" :disabled="disable.impo" v-on:click="validaFiltro" type="checkbox" ref="operation_impo" :checked="filters.filTipoOperacaoModalImportacao"><span></span></label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Exportação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.expo}">
                        <input id="operation_expo" value="1" :disabled="disable.expo" v-on:click="validaFiltro" type="checkbox" ref="operation_expo" :checked="filters.filTipoOperacaoModalExportacao"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Nacional</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.nac}">
                        <input id="operation_nac" value="3" :disabled="disable.nac" v-on:click="validaFiltro" type="checkbox"  ref="operation_nac" :checked="filters.filTipoOperacaoModalNacional"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- Filter Modal  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Marítimo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.mar}">
                        <input id="modal_mar" value="2" :disabled="disable.mar" v-on:click="validaFiltro" type="checkbox" ref="modal_mar" :checked="filters.filTipoOperacaoModalMaritimo"><span></span></label></div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Aéreo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.air}">
                        <input id="modal_air" value="1" :disabled="disable.air" v-on:click="validaFiltro" type="checkbox" ref="modal_air" :checked="filters.filTipoOperacaoModalAereo"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Terrestre</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.land}">
                        <input id="modal_land" value="3" :disabled="disable.land" v-on:click="validaFiltro" type="checkbox"  ref="modal_land" :checked="filters.filTipoOperacaoModalTerrestre"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <h6 class="mb-3 mt-5">{{ $t('origin_and_destination') }}</h6>
              <div class="row">
                <div class="col-md-6">
                  <div class="py-1">
                    <div class="md-form mb-0">
                      <input  ref="origin"  id="origin" :value="filters.filOrigem" class="md-form-control" type="text">
                      <label :class="{'active': filters.filOrigem}">{{ $t('kpi.value_freight.Origin') }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="py-1">
                    <div class="md-form mb-0">
                      <input  ref="destination"  id="destination" :value="filters.filDestino" class="md-form-control" type="text">
                      <label :class="{'active': filters.filDestino}">{{ $t('kpi.value_freight.Destiny') }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>
      <div style="margin-top: -4.6rem;" class="col-12">
        <template>
          <form class="nav-scroll" id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
            <div class="search-mobile" style="display:flex;margin-top: 1rem;">
              <select style="border-radius: 20px 0 0 20px; height: 3rem; border: 1px solid #ced4da; border-right: none !important; margin-left: 1rem;padding-left: 15px;margin-bottom: 0rem;" v-model="selected">
                <option id="one" value='1'>{{ $t('kpi.value_freight.Origin') }}</option>
                <option value='2'>{{ $t('kpi.value_freight.Destiny') }}</option>
                <option value='3'>{{ $t('form.customer.label') }}</option>
              </select>
              <div class="multiple" style="width: 45rem;min-height: 3rem; border: 1px solid #ced4da; border-left: 1px solid #ced4da; border-radius: 0 20px 20px 0; margin-bottom: 2rem;background-color: #fff;" v-switch="selected">
                <div style="width: 44.3rem; padding-top: 0.4rem;" v-case="'3'">
                  <div style="margin-top: 0rem;" class="multiple">
                    <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                  </div>
                </div>
                <div style="width: 44.3rem;" v-case="'1'">
                  <div style="margin-top: 0.4rem;" class="md-form mb-0">
                  <div class="input-group-icon input-group-icon-right mb-0">
                    <div style="margin-top: -0.3rem;" class="md-form mb-0 search">
                      <span class="input-icon input-icon-right" style="margin-top: 0.1rem;" v-on:click="sub()">
                        <i class="ti-search ti-search-control"></i>
                      </span>
                      <input  ref="origin"  id="origin" :value="filters.filOrigem" class="md-form-control search-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 44.3rem;" v-case="'2'">
                  <div style="margin-top: 0.4rem;" class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div style="margin-top: -0.3rem;" class="md-form mb-0">
                        <span class="input-icon input-icon-right" style="margin-top: 0.1rem;" v-on:click="sub()">
                          <i class="ti-search ti-search-control"></i>
                        </span>
                        <input  ref="destination"  id="destination" :value="filters.filDestino" class="md-form-control search-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Operation / Modal -->
          <div class="row row-mobile" style="margin-top: -1.2rem !important;align-items: center; align-content: center;justify-content: flex-start !important;flex-wrap: nowrap;display: flex;">
            <div class="coluna35 coluna35-mobile">
              <div style="height: 0%;margin-right: 2px;">
                <abbr title="Marítimo" >
                  <label class="switch" id="Maritimo" v-on:click="validaFiltro">
                    <input  id="modal_mar" value="2" :disabled="disable.mar" v-on:click="sub()" type="checkbox" ref="modal_mar" :checked="filters.filTipoOperacaoModalMaritimo">
                    <span class="slider">
                      <i class="material-icons material-symbols-outlined" style="font-size: 1.9rem;color:#80754E">directions_boat</i>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%;margin-right: 2px;">
                <abbr title="Aéreo">
                  <label class="switch" id="aer" v-on:click="validaFiltro" >
                    <input  value="1" :disabled="disable.air" v-on:click="sub()" type="checkbox" ref="modal_air" :checked="filters.filTipoOperacaoModalAereo">
                    <span class="slider">
                      <i class="material-icons material-symbols-outlined" style="font-size: 1.9rem;color:#80754E">flight</i>
                      </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Rodoviário">
                    <label class="switch" id="terrestre" v-on:click="validaFiltro" >
                    <input type="checkbox"  value="3" :disabled="disable.land" v-on:click="sub()" ref="modal_land" :checked="filters.filTipoOperacaoModalTerrestre">
                    <span class="slider">
                      <i class="material-icons material-symbols-outlined" style="font-size: 1.9rem;color:#80754E">local_shipping</i>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Importação">
                  <label class="switch" id="Importacao" v-on:click="validaFiltro" >
                    <input id="operation_impo" value="2" :disabled="disable.impo" v-on:click="sub()" type="checkbox" ref="operation_impo" :checked="filters.filTipoOperacaoModalImportacao">
                    <span class="slider">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 6.48 17.53 2 12.01 2C6.48 2 2 6.48 2 12C2 17.5167 6.4746 21.9946 12 22L12 19.96C11.17 18.76 10.52 17.43 10.09 16H12V14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12H16.5C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12H22ZM8.03 8H5.08C6.04 6.35 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM10.09 8C10.52 6.57 11.17 5.24 12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.34 18.92 8ZM8.03 16C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.65 5.08 16H8.03ZM7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12Z" fill="#80754E"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 14C13 13.4477 13.4477 13 14 13L21 13C21.5523 13 22 13.4477 22 14L22 21C22 21.5523 21.5523 22 21 22L14 22C13.4477 22 13 21.5523 13 21L13 14ZM15.5 15.0007C15.2239 15.0007 15 15.2246 15 15.5007L15 19.4948L15 19.5007C15 19.765 15.205 19.9814 15.4647 19.9995C15.4764 20.0003 15.4881 20.0007 15.5 20.0007L15.5016 20.0007L19.5 20.0007C19.7761 20.0007 20 19.7769 20 19.5007C20 19.2246 19.7761 19.0007 19.5 19.0007L16.7071 19.0007L19.8497 15.8582L19.1425 15.1511L16 18.2937L16 15.5007C16 15.2246 15.7761 15.0007 15.5 15.0007Z" fill="#80754E"/>
                      </svg>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Exportação">
                  <label class="switch" id="Exportacao" v-on:click="validaFiltro" >
                    <input id="operation_expo" value="1" :disabled="disable.expo" v-on:click="sub()" type="checkbox" ref="operation_expo" :checked="filters.filTipoOperacaoModalExportacao">
                      <span class="slider">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.5167 17.5254 21.9946 12 22L12 19.96C12.83 18.76 13.48 17.43 13.91 16H12V14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12H7.5C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12H2ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM13.91 8C13.48 6.57 12.83 5.24 12 4.04C11.17 5.24 10.52 6.57 10.09 8H13.91ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM15.97 16C15.65 17.25 15.19 18.45 14.59 19.56C16.43 18.93 17.96 17.65 18.92 16H15.97ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z" fill="#80754E"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13C2.44772 13 2 13.4477 2 14V21C2 21.5523 2.44772 22 3 22H10C10.5523 22 11 21.5523 11 21V14C11 13.4477 10.5523 13 10 13H3ZM4.5 15C4.22386 15 4 15.2239 4 15.5C4 15.7761 4.22386 16 4.5 16H7.29284L4.14642 19.1464L4.85353 19.8535L8 16.7071V19.5C8 19.7761 8.22386 20 8.5 20C8.77614 20 9 19.7761 9 19.5V15.5C9 15.4655 8.9965 15.4318 8.98984 15.3992C8.94322 15.1714 8.74162 15 8.5 15M4.5 15H8.49869H4.5Z" fill="#80754E"/>
                        </svg>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Nacional">
                  <label class="switch" id="Nacional" v-on:click="validaFiltro" >
                    <input v-on:click="sub()" id="operation_nac" value="3" :disabled="disable.nac" type="checkbox"  ref="operation_nac" :checked="filters.filTipoOperacaoModalNacional">
                    <span class="slider">
                      <img src="../../../assets/css/icons/rc-icons/brasil_estados.svg.svg" style="fill:#80754E; width: 2rem; margin-left: 0.2rem;">
                    </span>
                  </label>
                </abbr>
              </div>
            </div>
            <div class="col20" style="display:flex;min-width: 33%;max-width: 56%;margin-left: -2rem;margin-bottom: 0;margin-left: 1rem;">
              <div>
                <h6 class="mb-0 mt-4" style="margin-bottom: -0.5rem !important;color: #5e5e5e94; margin-top: 0 !important;margin-left: 1rem;">{{ $t('kpi.volume.period') }}</h6>
                <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.begin_date')"
                              style="border-bottom: 1px solid #ced4da !important;"
                              id="begin_date" ref="begin_date" readonly
                              class="form-date md-form-control" type="text"
                              :value="$util.formatDate(filters.filPeriodoDtInicio)">
                        <label :class="{'active': filters.filPeriodoDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Data Final -->
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.end_date')"
                              style="border-bottom: 1px solid #ced4da !important;"
                              id="end_date" ref="end_date" readonly class="form-date md-form-control"
                              type="text" :value="$util.formatDate(filters.filPeriodoDtFim)">
                        <label :class="{'active': filters.filPeriodoDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <h6 class="mb-3 mt-5"></h6>
            </div>
            <div  style="padding-bottom: 1rem;padding-right: 12rem; width: 1%; position: absolute;right: 18rem;">
              <div class="md-form mb-0" style="margin-top: 0rem;">
                <div class="row">
                  <div class="col-3" style="max-width: 75%;">
                    <button type="submit"  id="button" class="btn btn-primary btn-fill btn-block mr-2 button">Aplicar Filtros
                    </button>
                  </div>
                  <!-- <div class="col-6">
                    <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                      $t('filter.reset') }}
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </form>
        </template>
      </div>
      <div style="display:none">
        <canvas id="consignee_processes_pie"></canvas>
        <canvas id="shipper_processes_pie"></canvas>
      </div>
      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3 filtros" style="display: flex; ">
        <div class="itemContent mx-1 mb-2" style="width: 8.5rem;">
          <button type="button" v-on:click="resetFilter" id="btn" class="itemContent mx-1 mb-2 btn btn-fill btn-block mr-2">Limpar Filtros
            <i class="material-icons material-symbols-outlined" style="font-size: .9rem;color:#80754E">
              filter_list
            </i>
          </button>
        </div>
        <filter-container :activeFilterList="activeFilterList" />
      </div>
      <div class="col-12 titulo">
        <h2>Importadores/Exportadores</h2>
        <h5>KPI > Importadores/Exportadores</h5>
      </div>
      <div class="col-12 mb-3" style="margin: 0!important;">
        <div class="btn">
          <div class="select-radio ex1">
            <br>
            <label class="radio origens active" v-on:click="isOpen= false">
              <input type="radio" name="tipo" value="1" id="importador" checked>
              <span>{{ $t('kpi.consignee_shipper.top_consignee')}}</span>
            </label>
            <label class="radio destino" v-on:click="isOpen= true" style="margin-left: 0;">
              <input type="radio"  name="tipo" value="2" id="exportador">
              <span>{{ $t('kpi.consignee_shipper.top_shipper')}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">

            <div v-show="!isOpen" class="chart-data row">
              <table class="table table-hover consignee_processes_pie ">
                <thead>
                <tr>
                  <th class="text-left" width="29%">Nome</th>
                  <th class="text-center" width="16%">Teus</th>
                  <th class="text-center" width="18%">Tons</th>
                  <th class="text-center" width="8%">{{ $t('kpi.value_freight.processes') }}</th>
                  <th  width="10%">%</th>
                </tr>
                </thead>
                <tbody class="content">
                  <tr v-for="(item, index) in consignees.labels" :data-idx="index" :key="index">
                    <td>{{ item }}</td>
                    <td class="text-center">{{ numberFormat(consignees.teus.values[index], 0, ',', '.') }}</td>
                    <td class="text-center">{{ numberFormat(consignees.kg.values[index]/1000, 2, ',' , '.') }}</td>
                    <td class="text-center">{{ numberFormat(consignees.files.values[index], 0, ',', '.') }}</td>
                    <td width="10%" class="text-center">
                      <div class="boxstyle">
                        {{((consignees.files.values[index] * 100)/ infoChart.consignees.total_files).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1}) }}%
                      </div>
                    </td>
                  </tr>
                  <!-- If no have content to show -->
                  <tr v-if="consignees.files.length <= 0">
                    <td colspan="6">{{ $t('noting to result')}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div  v-show="isOpen" class="chart-data row">
              <table class="table table-hover  shipper_processes_pie ">
                <thead>
                  <tr>
                    <th class="text-left" width="29%">Nome</th>
                    <th class="text-center" width="16%">Teus</th>
                    <th class="text-center" width="18%">Tons</th>
                    <th class="text-center" width="8%">{{ $t('kpi.value_freight.processes') }}</th>
                    <th  width="10%">%</th>
                  </tr>
                </thead>
                <tbody class="content">
                  <tr v-for="(item, index) in shippers.labels" :data-idx="index" :key="index">
                    <td>{{ item }}</td>
                    <td class="text-center">{{ numberFormat(shippers.teus.values[index], 0, ',', '.') }}</td>
                    <td class="text-center">{{ numberFormat(shippers.kg.values[index]/1000, 2, ',' , '.') }}</td>
                    <td class="text-center">{{ numberFormat(shippers.files.values[index], 0, ',', '.') }}</td>
                    <td width="10%" class="text-center">
                      <div class="boxstyle">
                        {{((shippers.files.values[index] * 100)/ infoChart.shippers.total_files).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1}) }}%
                      </div>
                    </td>
                  </tr>
                  <!-- If no have content to show -->
                  <tr v-if="shippers.files.length <= 0">
                    <td colspan="6">{{ $t('noting to result')}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import Vue from 'vue'
import VSwitch from 'v-switch-case'
import dayjs from 'dayjs'
import KpiService from '@/services/KPI/KpiService'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainerProcess'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chart.js/dist/Chart.min.css'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// eslint-disable-next-line
import '@/assets/js/custom/data.chartJS.data.render.js'

// Services
import CustomerService from '@/services/CustomerService'

// Components

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelectProcess.vue'
Vue.use(VSwitch)
// Por padrão label vem habilitada para todos os gráficos (Removendo para poder colocar apenas nos que desejar)
Chart.plugins.unregister(ChartDataLabels)

export default {
  name: 'ConsigneeShipperKPI',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.volume.title') + ' - %s'
    }
  },
  data () {
    return {
      selected: '1',
      isOpen: false,
      customerList: [],
      hasData: false,
      shippers: {
        files: [],
        labels: []
      },
      consignees: {
        files: [],
        labels: []
      },
      infoChart: null,
      shippersChart: null,
      consigneesChart: null,
      kpiDados: null,
      isLoading: false,
      fullPage: true,
      activeFilterList: {
        customer: [],
        period: null,
        modal: null,
        operation: null,
        beginDate: null,
        endDate: null,
        origin: null,
        destination: true
      },
      filter: {},
      disable: {
        expo: false,
        impo: false,
        nac: false,
        mar: false,
        air: false,
        land: false
      }
    }
  },
  components: {
    FilterSideBar,
    Loading,
    FilterContainer,
    MultipleSelect
  },
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.buildCharts()

    if (this.user) {
      this.getKpiConsigneeShipperData()
    }

    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    sub () {
      document.getElementById('button').click()
    },
    buildFilters () {
      /*
      this.filter.period = this.filters.filPeriodo
      */

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }
      if (this.filters.filTipoOperacaoModalNacional) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalNacional)
      }

      // Modal Filter
      this.filter.modal = []
      if (this.filters.filTipoOperacaoModalTerrestre) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalTerrestre)
      }
      if (this.filters.filTipoOperacaoModalMaritimo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalMaritimo)
      }
      if (this.filters.filTipoOperacaoModalAereo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalAereo)
      }

      // Filter Processes
      this.filter.processes = []
      if (this.filters.situacaoAbertos) {
        this.filter.processes.push(this.filters.situacaoAbertos)
      }
      if (this.filters.situacaoEmbarcados) {
        this.filter.processes.push(this.filters.situacaoEmbarcados)
      }

      // Period Date Filter
      this.filter.beginDate = this.filters.filPeriodoDtInicio
      this.filter.endDate = this.filters.filPeriodoDtFim

      // Origins and destination
      this.filter.origin = this.filters.filOrigem
      this.filter.destination = this.filters.filDestino

      // Customer
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
    },
    // Filter
    applyFilter () {
      this.customerList = []
      let dayParse = 'DD/MM/YYYY'
      // Period
      /*
      if (this.$refs.view_type_weekly.classList.contains('active')) {
        this.filters.filPeriodo = 'weekly'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }
      */

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null
      this.filters.filTipoOperacaoModalNacional = this.$refs.operation_nac.checked ? parseInt(this.$refs.operation_nac.value) : null

      // Modal Filter
      this.filters.filTipoOperacaoModalTerrestre = this.$refs.modal_land.checked ? parseInt(this.$refs.modal_land.value) : null
      this.filters.filTipoOperacaoModalMaritimo = this.$refs.modal_mar.checked ? parseInt(this.$refs.modal_mar.value) : null
      this.filters.filTipoOperacaoModalAereo = this.$refs.modal_air.checked ? parseInt(this.$refs.modal_air.value) : null

      // Origin and Destination
      this.filters.filOrigem = this.$refs.origin.value ? this.$refs.origin.value : null
      this.filters.filDestino = this.$refs.destination.value ? this.$refs.destination.value : null

      // Date period
      this.filters.filPeriodoDtInicio = dayjs(this.$refs.begin_date.value, dayParse).isValid() ? (dayjs(this.$refs.begin_date.value, dayParse).hour(0).minute(0).second(0)).toISOString() : null
      this.filters.filPeriodoDtFim = dayjs(this.$refs.end_date.value, dayParse).isValid() ? dayjs(this.$refs.end_date.value, dayParse).hour(23).minute(59).second(59).toISOString() : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      /* Todo: Reload Charts */
      this.getKpiConsigneeShipperData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null
      //  weekly monthly yearly
      this.filters.filPeriodo = 'monthly'
      //  Operação
      this.filters.filTipoOperacaoModalExportacao = 1
      this.filters.filTipoOperacaoModalImportacao = 2
      this.filters.filTipoOperacaoModalNacional = 3
      //  Modal
      this.filters.filTipoOperacaoModalAereo = 1
      this.filters.filTipoOperacaoModalMaritimo = 2
      this.filters.filTipoOperacaoModalTerrestre = 3
      //  Situação
      this.filters.situacaoAbertos = 1
      this.filters.situacaoEmbarcados = 2
      // Period Date
      this.filters.filPeriodoDtInicio = (dayjs().add(-1, 'year').month(0).date(1).hour(0).minute(0).second(0)).toISOString()
      this.filters.filPeriodoDtFim = dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).toISOString()

      // Origin  and destinations
      this.filters.filOrigem = null
      this.filters.filDestino = null

      // Customer
      this.filters.filCliente = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      /* Todo: Reload Charts */
      this.getKpiConsigneeShipperData()
    },
    validaFiltro () {
      let contOp = 0
      let contMo = 0

      contOp = this.$refs.operation_expo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_impo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_nac.checked ? (contOp + 1) : contOp

      contMo = this.$refs.modal_mar.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_air.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_land.checked ? (contMo + 1) : contMo

      this.disable.expo = false
      this.disable.impo = false
      this.disable.nac = false
      this.disable.mar = false
      this.disable.air = false
      this.disable.land = false

      if (contOp === 1) {
        switch (true) {
          case this.$refs.operation_expo.checked:
            this.disable.expo = true
            break
          case this.$refs.operation_impo.checked:
            this.disable.impo = true
            break
          case this.$refs.operation_nac.checked:
            this.disable.nac = true
            break
        }
      }

      if (contMo === 1) {
        switch (true) {
          case this.$refs.modal_mar.checked:
            this.disable.mar = true
            break
          case this.$refs.modal_air.checked:
            this.disable.air = true
            break
          case this.$refs.modal_land.checked:
            this.disable.land = true
            break
        }
      }
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    // End Filter
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      if (number !== parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    elementHover (chart, idx) {
      var meta = chart.getDatasetMeta(0)
      var rect = chart.canvas.getBoundingClientRect()
      var point = meta.data[idx].getCenterPoint()
      var evt = new MouseEvent('mousemove', {
        clientX: rect.left + point.x,
        clientY: rect.top + point.y
      })
      var node = chart.canvas
      node.dispatchEvent(evt)
    },
    elementOut (chart) {
      // var meta = chart.getDatasetMeta(0)
      var rect = chart.canvas.getBoundingClientRect()
      var evt = new MouseEvent('mousemove', {
        clientX: rect.left,
        clientY: rect.top
      })
      var node = chart.canvas
      node.dispatchEvent(evt)
    },
    addMultiplePieData (chart, data, labels, decimals) {
      decimals = (typeof decimals !== 'undefined') ? decimals : 0

      chart.data.datasets = []
      chart.update()

      var chartData = []
      var colors = []

      if (data.hasOwnProperty('values')) {
        for (var index in data.values) {
          var value = data.values[index]
          chartData.push(value)
          colors.push(data.colors[index])
        }
      } else {
        colors = []
        labels = []
        chartData = [0, 100]
      }

      let dataSet = {
        data: chartData,
        backgroundColor: colors,
        label: labels,
        borderWidth: 0
      }

      chart.data.datasets.push(dataSet)

      chart.update()
    },
    addHoverEvent (chart) {
      let canvasID = this.$jQuery(chart.canvas).attr('id')
      let me = this

      this.$jQuery(document).ready(function () {
        me.$jQuery('.chart-data').on('mouseenter', '.table.' + canvasID + ' .content tr', function () {
          if (me.$jQuery(this).data('idx')) {
            me.elementHover(chart, me.$jQuery(this).data('idx'))
          }
        }).on('mouseleave', '.table.' + canvasID + ' .content tr', function () {
          me.elementOut(chart)
        })
      })
    },
    newPie (selector) {
      let me = this

      var config = {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [0, 100],
            backgroundColor: []
          }],
          labels: []
        },
        options: {
          elements: {
            center: {
              text: '0%',
              color: '#00bcd4', // Default is #000000
              fontStyle: 'Arial', // Default is Arial
              sidePadding: 60 // Defualt is 20 (as a percentage),
            }
          },
          tooltipFontSize: 10,
          tooltips: {
            callbacks: {
              title: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem[0].datasetIndex].label[tooltipItem[0].index]

                if (!label) {
                  return null
                }

                return data['labels'][tooltipItem[0]['index']]
              },
              label: function (tooltipItem, data) {
                // Get current DataSet
                let dataset = data.datasets[tooltipItem.datasetIndex]
                // Sum total of dataset to percente calc
                let total = dataset.data.reduce(function (previousValue, currentValue) {
                  return previousValue + currentValue
                })
                let currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                // Calculate percentage
                var percentage = ((currentValue / total) * 100.0)

                if (currentValue <= 0) {
                  return null
                }

                let label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index]

                if (label) {
                  return label + ': ' + me.numberFormat(percentage, 2, ',', '.') + '%'
                }

                return false
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 14,
            titleSpacing: 10,
            titleMarginBottom: 10,
            bodySpacing: 4,
            titleFontColor: '#3f3f3f',
            bodyFontColor: '#333',
            bodyFontSize: 13,
            cornerRadius: 3,
            yPadding: 10,
            xPadding: 10,
            displayColors: true
          },
          legend: {
            display: false
          },
          cutoutPercentage: 0,
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      }

      let processesDonnutctx = document.getElementById(selector).getContext('2d')
      return new Chart(processesDonnutctx, config)
    },
    getKpiConsigneeShipperData () {
      // let me = this
      this.isLoading = true

      if (this.$store.getters.userInfo) {
        KpiService.getConsigneesShippers(this.$store.getters.userInfo.customer_id, this.filter.period, this.filter).then(res => {
          this.hasData = res.data.hasData

          let emptyValue = {
            colors: [],
            labels: [],
            files: []
          }

          this.shippers = emptyValue
          this.consignees = emptyValue

          if (this.hasData) {
            if (res.data.dataset.hasOwnProperty('shippers')) {
              this.shippers = res.data.dataset.shippers.data
            }

            if (res.data.dataset.hasOwnProperty('consignees')) {
              this.consignees = res.data.dataset.consignees.data
            }

            this.infoChart = res.data.info

            this.addMultiplePieData(this.shippersChart, this.shippers.files, this.shippers.labels)
            this.addHoverEvent(this.shippersChart)

            this.addMultiplePieData(this.consigneesChart, this.consignees.files, this.consignees.labels)
            this.addHoverEvent(this.consigneesChart)
          } else {
            this.addMultiplePieData(this.shippersChart, emptyValue, emptyValue.labels, 2)

            this.addMultiplePieData(this.consigneesChart, emptyValue, emptyValue.labels, 2)
          }

          this.isLoading = false
        })
      }
    },
    buildCharts () {
      // let data = null
      // if (this.shippersChart) { this.shippersChart.destroy() }
      // if (this.consigneesChart) { this.consigneesChart.destroy() }
      this.shippersChart = this.newPie('shipper_processes_pie')
      this.consigneesChart = this.newPie('consignee_processes_pie')
    },
    setFilterPeriod (period) {
      this.filters.filPeriodo = period
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getKpiConsigneeShipperData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .select-radio {
     margin: 0px;
   }
   .select-radio input {
     display: none;
   }
   .select-radio label {
     display: inline-block;
     cursor: pointer;
   }
   .ex1 span {
     background-color: #d1d1d1;
     display: block;
     padding: 5px 10px 5px 25px;
     border: 2px solid #ddd;
     border-radius: 5px;
     position: relative;
     transition: all 0.25s linear;
   }
   .ex1 .span {
     background-color: #d1d1d1;
   }
   .ex1 span:before {
     content: '';
     position: absolute;
     left: 5px;
     top: 50%;
     -webkit-transform: translatey(-50%);
     transform: translatey(-50%);
     width: 15px;
     height: 15px;
     border-radius: 50%;
     background-color: #ddd;
     transition: all 0.25s linear;
   }
   .ex1 span:hover{
      background-color: #d1d1d159;
   }
   .ex1 input:checked + span {
     background-color: #fff;
     box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
   }
   .ex1 .origens input:checked {
     background-color: #d1d1d159;
   }
   .ex1 .origens input:checked + span:before {
     background-color: #bdb5a2;
   }
   .ex1 .destino input:checked + span {
     background-color: #fff;
   }
   .ex1 .destino input:checked + span:before {
     background-color: #bdb5a2;
   }
   .btn{
     padding: 0px;
     margin: 0;
     margin-bottom: -5px;
   }
   .row{
     justify-content: space-between;
     margin-top: 0.65rem !important;
   }
   .radio span::after {
     background: #52647700;
   }
    .header-process{
     display:flex;
     overflow: hidden;
   }
   .header-resum-container{
     display: flex;
     /* justify-content: space-around; */
   }
   .header-resum{
     background-color: #3D3D3D;
     color: #fff !important;
   }
   .header-resum h2, .header-resum h5, .header-resum h3{
     color: #fff !important;
     padding: 0 0 0 10px;
   }
   .h5, .header-resum-container h5, .header-resum h5{
     font-size: 8pt;
     padding: 0 0 0 10px;
   }
   .header-resum-container h5{
     display: flex;
   }
   .header-resum h3{
     font-size: 1rem;
     padding: 0% 0 6% 0;
   }
   .header-resum, .header-maritime-process, .header-air-process, .header-road-process{
     display: flex;
     width: 25%;
     flex-direction: column;
     overflow: hidden;
     padding: 1.6% 1.6%;
     border-radius: 1rem;
     margin:0 0.5% 0 0.5%;
     align-items: stretch;
   }
   .header-maritime-process, .header-air-process, .header-road-process{
     background-color: #fff;
   }
   .header-maritime-process h3, .header-air-process h3, .header-road-process h3{
     color: #666666;
     font-size: 13pt;
   }
  .assegurados-texto{
     width: 63px;
     height: 20px;
     left: 20px;
     top: 108px;
     font-family: 'Pluto Sans';
     font-style: normal;
     font-weight: normal;
     font-size: 0.9rem !important;
     line-height: 18px;
     display: flex;
     align-items: center;
     color: #3D3D3D;
   }
   .assegurados-quantidade{
     position: static;
     width: 45px;
     height: 27px;
     left: 0px;
     top: 0px;
     font-family: 'Pluto Sans';
     font-style: normal;
     font-weight: normal;
     font-size: 22px;
     line-height: 27px;
     display: flex;
     align-items: flex-end;
     letter-spacing: -0.01em;
     color: #80754e;
     flex: none;
     order: 0;
     flex-grow: 0;
     margin: 0px 0px;
   }
   .cab-tittle{
     display: flex;
     align-items: center;
     justify-content: space-between;
   }
   .assegurados-container{
     display: flex;
     align-items: flex-end;
     justify-content: space-between;
     padding-top: 0.6rem;
   }
   .assegurados{
     display: flex;
     flex-direction: column;
     margin-right: 4%;
   }
   .titulo h2{
     /* Light/24pt */
     font-family: 'Pluto Sans';
     font-style: normal;
     font-weight: 370;
     font-size: 24px;
     line-height: 30px;
     /* identical to box height */
     letter-spacing: -0.02em;
     /* Font/Gold */
     color: #5A4D34;
   }
   .titulo h5{
     /* Regular/12pt */
     font-family: 'Pluto Sans';
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     line-height: 18px;
     /* identical to box height, or 150% */
     display: flex;
     align-items: flex-end;
     /* Font/Dark */
     color: #3D3D3D;
   }
   .titulo{
     margin-left: 0%;
   }
   .boxstyle{
     height: 90%;
     background-color: #3D3D3D;
     color: #fff;
     border-radius: 5px;
     width: 70%;
     margin-left: 15%;
   }
   @media only screen and (max-width: 1600px) {
  }
  @media only screen and (min-width: 600px) {
    /* .filtros{
      padding-left: 55px;
    } */
    .table-clickable tbody tr:hover td{
      cursor: default;
    }
    ._horizontal-scroll{
      width: 100%;
      overflow-x: hidden !important;
    }
    .h6-mobile{
      display: none !important
    }
  }
 @media only screen and (max-width: 600px) {
    .calendar-mobile{
      margin-top: -1.5rem
    }
    .row-mobile{
      margin-top: 0;
      align-items: center;
      place-content: center space-evenly;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
    }
    .h6{
      display: none !important
    }
    .row-search{
      display: flex;
      flex-direction: column !important;
    }
    .horizontal-scroll{
      width: 100%;
      overflow-x: scroll !important;
      overflow-y: hidden;
    }
    .input-mobile{
      height: 4.7rem !important;
    }
    .nav-scroll-template{
      max-width: 100% !important;
    }
    .no-bottom-margin{
      width: 320%;
    }
    ._horizontal-scroll{
      width: 100%;
      overflow-x: scroll !important;
      overflow-y: hidden;
    }
    .search-mobile{
      width: 90%;
      margin-bottom: 1rem;
    }
    .search-mobile select{
      width: 88%;
    }
    #linha-vertical{
      display: none !important;
    }
    .coluna35{
      margin-left: 0 !important;
      flex-direction: row;
      flex-wrap: nowrap;
      max-width: 100% !important;
      width: 100% !important;
    }
    .col20{
      width: 100% !important;
      margin: 0 !important;
    };
  }

  input{
    border: none !important;
  }
  .disableLabel span{
    background-color: rgba(132,117,78,0.6) !important;
    border-color: rgba(132,117,78,0.1) !important;
  }
  .switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .vuetable-slot{
    cursor: default !important;
  }
  .slider {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(185, 185, 185, 0.473);
    border-radius: 12px;
    -webkit-transition: .7s;
    transition: .7s;
    border: 1px solid #e4e4e459;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: .4s;
    transition: .4s;
  }
  .search .md-form-control {
     background-color: rgb(255, 255, 255) !important;
     border: none !important;
  }
  input:checked + .slider {
    background-color: #ffffff00;
    border: 1px solid rgb(128, 117, 78);
    border-radius: 12px;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .coluna35{
    max-width: 34%;
    min-width: 18%;
    padding-left: 13px;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #linha-vertical {
      height: 2rem;
      border-right: 1px solid #C4C4C4;
      margin: 0 1rem 0.5rem -10rem;
  }
  .button{
    width: 10rem;
    height: 3rem;
    color: #555555;
    background-color: #c4c4c400;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #btn:hover { background-color: #E0E0E0 }
  #btn{
    /* Limpar Filtros */
    height: 32px;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    background-color: none;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 1px solid #E0E0E0;
    color: #666666;
    display: inline-block;
    padding: 1px 5px;
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;

  }
  .nav-item .active{
    border-bottom: 1px solid #E0E0E0 !important;
  }
  nav {
    position: sticky;
    top: 0;
  }
  .search-control{
    margin: 0 0 2px 0 !important;
  }
  .ti-search-control{
    margin: 0 0 -6px 0 !important;
  }
</style>
